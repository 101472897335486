.project-section {
  background-color: #fff;
}

.project-section-container {
  width: 80%;
  margin: 0 auto;
  padding: 20px 0;
  max-width: 1170px;
}

.projects-title {
  text-align: center;
  margin-bottom: 25px;
  font-size: 2.5rem;
}

@media (min-width: 693px) {
  .project-gallery-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
}

@media (min-width: 1060px) {
  .project-gallery-container {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media (max-width: 420px) {
  .project-section-container {
    width: 90%;
  }
}