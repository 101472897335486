.reviews-section {
  background-color: #fff;
}

.reviews-section-standalone {
  min-height: calc(100vh - 220px);
}

.reviews-section-container {
  width: 85%;
  margin: 0 auto;
  background-color: #fff;
  padding-bottom: 70px;
}

.reviews-title {
  text-align: center;
  margin-bottom: 25px;
  font-size: 2.5rem;
  padding-top: 20px;
}
