@import url('https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  font-family: 'Titillium Web', sans-serif;
}

