.notfound-section {
  background-color: #fff;
  min-height: calc(100vh - 220px);
}

.notfound-section-container {
  width: 85%;
  margin: 0 auto;
}

.notfound-title {
  text-align: center;
  margin-bottom: 25px;
  font-size: 2.5rem;
  padding-top: 20px;
}