.contact-section {
  background-color: #d0cfcf;
}

.contact-section-standalone {
  min-height: calc(100vh - 220px);
  background-color: #d0cfcf;
}

.contact-section-container {
  width: 80%;
  margin: 0 auto;
  padding: 20px 0;
  max-width: 1170px;
}

.contact-title {
  text-align: center;
  margin-bottom: 25px;
  font-size: 2.5rem;
}

.contact-text-cont {
  width: 100%;
  padding-bottom: 30px;
}

.contact-details-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px;
}

.contact-details {
  line-height: 1.8;
  display: flex;
  flex-direction: column;
}

.cont {
  font-size: 1rem;
  display: flex;
  align-items: center;
}

.cont a {
  margin-left: 7px;
  text-decoration: none;
  color: black;
}

.social-link {
  margin-right: 5px;
  font-size:  2.4rem;
  cursor: pointer;
  color: #7a7d7d;
  display: flex;
}

.social-link:hover {
  color: black;
  transition: all 0.3s linear;
}

.contact-logo {
  width: 120px;
  height: 94px;
}

.social-media-links-container {
  display: flex;
  justify-content: flex-start;
  padding-top: 8px;
  margin-left: -4px;
}

@media (min-width: 960px) {
  .contact-info-container {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    gap: 50px;
  }
  .contact-text-cont {
    padding-bottom: 0;
    text-align: justify;
  }
}

@media (max-width: 420px) {
  .contact-section-container {
    width: 90%;
  }
}
