.project-image-container {
  position: relative;
  color: white;
  margin-bottom: 20px;
}

@media (min-width: 693px ) {
  .project-image-container {
    margin-bottom: 0px;
  }
}

.project-info {
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.project-info:hover {
  opacity: 1;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  transition: all 0.2s linear;
}

.project-image {
  display: block;
  width: 100%;
}

.project-heading {
  padding: 10px 0;
}

.project-desc {
  padding: 10px 0;
}

.zoom {
  padding: 10px 0;
}



